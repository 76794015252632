import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
@Component
export default class multiSelect extends Vue {
  @Prop() private list!: Array<any>
  @Prop() private label!: string
  @Prop({ default: 150 }) private maxWidth!: number
  @Prop() private icon!: string

  data: Array<any> = []
  added: Array<any> = [
    {
      text: '',
      value: '',
      disabled: false,
    },
  ]

  mounted() {
    this.data = this.list.map((x: any) => ({
      ...x,
      disabled: false,
      quantity: 1,
    }))
  }

  get updateList(): Array<any> {
    return this.added
  }

  // @Watch("menu")
  // onPropertyChanged(newVal: Boolean) {
  // 	if (this.isTouch) {
  // 		this.openMenu = newVal
  // 	}
  // }

  addRow() {
    this.added.push({
      text: '',
      value: '',
      disabled: false,
      quantity: 1,
    })
  }

  add() {
    if (this.added[this.added.length - 1].quantity > 0) {
      if (this.added[this.added.length - 1].value != '') {
        if (this.list.length + 1 > this.added.length) {
          const objIndex = this.data.findIndex(
            (obj: any) => obj.value == this.added[this.added.length - 1].value,
          )
          this.data[objIndex].disabled = true
          this.added[this.added.length - 1].disabled = true
          this.$emit(
            'updateList',
            this.added.map((x: any) => ({
              value: x.value,
              quantity: x.quantity,
            })),
          )
        } else {
          this.$buefy.toast.open({
            message: `No hay más opciones para agregar`,
            type: 'is-warning',
          })
        }
      } else {
        this.$buefy.toast.open({
          message: `Seleccione una opción`,
          type: 'is-warning',
        })
      }
    } else {
      this.$buefy.toast.open({
        message: `Debe llenar todos los campos, no puede agregar números negativos`,
        type: 'is-warning',
      })
    }
  }

  remove(index: any) {
    if (this.added.length == 1) {
      this.addRow()
    }
    const objIndex = this.data.findIndex(
      (obj: any) => obj.value == this.added[index].value,
    )
    this.data[objIndex].disabled = false
    this.added.splice(index, 1)
  }
}
